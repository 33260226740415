import React from 'react';
import {CarSortSelect} from "./CarSortSelect";

export const CarSortBar = ({fnSortChange}) => {
    return (
        <>
            <div className='flex items-center rounded bg-gray-200 pr-5 pl-3 h-[50px] min-w-[330px] max-w-[330px]'>
                <div className='text-xs font-bold mr-4 pr-4 h-[50px] flex items-center'>SORT BY</div>
                <div><CarSortSelect onChange={(value) => fnSortChange(value)} /></div>
            </div>
        </>
    )
}