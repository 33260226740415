import React from 'react';
import {Select} from '@mantine/core';
export const CarBrandSelect = ({value, makes, onChange}) => {
    const listData = () => {
        let result = [];
        makes.map((item) => result.push({image: item.make_logo, label: item.make_name, value: item.make_id.toString()}))
        return result;
    }

    return (
        <>
            <Select
                placeholder={"Brand "}
                value={value}
                onChange={onChange}
                data={listData()}
                maxDropdownHeight={400}
                clearable
            />
        </>
    )
}