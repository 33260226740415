import React from "react";

export const MaterialIconContainer = ({icon, className}) => {
    return (
        <>
            <span className={`material-symbols-outlined ${className}`}>
                {icon}
            </span>
        </>
    )
}