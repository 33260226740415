import {Loader} from "@mantine/core";
import React from "react";

export const Waiting = ({title = 'Loading car list ...'}) => {

    return (
        <div className='w-screen h-screen flex items-center justify-center'>
            <div className='mb-[100px] grid grid-cols-1 place-items-center'>
                <div className=''><Loader color="#CFB77B" variant="bars" size={100}/></div>
                <div className='animate-bounce text-2xl mt-2 font-semibold text-purchase-color leading-tight tracking-tight'>{title}</div>
            </div>
        </div>
    )

}