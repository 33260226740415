import React from "react";

export const CarzelleDeliveryInfo = () => {
    return <>
        <div className="text-xs font-bold text-gray-400">SELLER</div>
        <div className="font-bold">CARZELLE A/S</div>
        <div>Bakketoften 3</div>
        <div>8541 Skødstrup</div>
        <div>DK-Denmark</div>
        <div className="flex mt-3 items-center justify-between">
            <div className="mr-2 text-gray-600 text-sm">Phone:</div>
            <div className="text-sm">+45 7241 0000</div>
        </div>
        <div className="flex items-center justify-between">
            <div className="mr-2 text-gray-600 text-sm">Email:</div>
            <div className="text-sm">info@carzelle.com</div>
        </div>
        <div className="flex items-center justify-between">
            <div className="mr-2 text-gray-600 text-sm">VAT:</div>
            <div className="text-sm">DK44210223</div>
        </div>
    </>;
}