import React from "react";

export const WidgetContainer = ({title, className, children}) => {
    return (
        <>
            <div className={'bg-gray-100 shadow-xl rounded-xl min-h-[200px] opacity-75 p-8 ' + className}>
                <h2 className="font-semibold text-3xl">
                    {title}
                </h2>
                <div className='mt-4'>
                    {children}
                </div>
            </div>
        </>
    )
}