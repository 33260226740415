import {formatNumberWithSeparator, getDayOfMonth, getMonthShortName, getTwoDigitYear} from "../../Hooks/UseGenericFunctions";
import React, {useEffect, useState} from "react";
import {Select, Tooltip} from "@mantine/core";
import {axiosInstance} from "../../Api/AxiosConfig";
import {CarAttribute} from "../../Components/Cars/CarAttribute";
import {CarImageModal} from "../../Components/Cars/CarImageModal";
import {CarNavigationThumbs} from "../../Components/Cars/CarNavigationThumbs";
import {OrderTransportSelection} from "./OrderTransportSelection";

export const OrderLine = ({orderLine, rowNumber, selectedCarId, setSelectedCarId, showPickupOptions = false}) => {
    const [expandedView, setExpandedView] = useState(false);
    const [deliveryChoice, setDeliveryChoice] = useState(orderLine.transport_select.currentSelect.hash === undefined ? null : orderLine.transport_select.currentSelect.hash);
    const [data, setData] = useState([]);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showImageIndex, setShowImageIndex] = useState(0);
    const [showPrimaryImage, setShowPrimaryImage] = useState(false);
    const trackNTraceAvailable =
        Array.isArray(data.transports) && data.transports.some(transport => transport.tt_url) ||
        Array.isArray(data.shipments) && data.shipments.some(shipment => shipment.tt_url);

    const handleThumbnailClick = (index) => {
        setShowImageIndex(index);
        setShowImageModal(true);
    }

    const handleExpandContractClick = () => {
        setShowPrimaryImage(false);
        setExpandedView(!expandedView);
        setSelectedCarId(orderLine.hash);
    }

    useEffect(() => {
        if (orderLine.hash !== selectedCarId) {
            setExpandedView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCarId]);


    useEffect(() => {
        if (expandedView) {
            axiosInstance
                .get(orderLine.info_endpoint)

                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedView]);

    const isEven = () => {
        return rowNumber % 2 === 0;
    }

    let rowColorClass = isEven() ? 'bg-white' : 'bg-gray-200';
    return (
        <div>
            <div className="flex flex-row items-center mb-2 text-gray-600 text-sm cursor-pointer">
                <div className={`rounded py-1 flex items-center px-4 shadow text-gray-600 ${rowColorClass} mr-4`} onClick={handleExpandContractClick}>
                    <div className="h-full w-[50px] mr-2">
                        <img className='rounded-full h-10 w-10 object-scale-down' src={orderLine.list_image} alt={'image for ' + orderLine.designation}/>
                    </div>
                    <div className="w-[80px]">
                        {orderLine.car_id}
                    </div>
                    <div className="w-[200px]">
                        {orderLine.vin_no}
                    </div>
                    <div className="w-[300px] overflow-hidden whitespace-nowrap mr-6">
                        {orderLine.designation}
                    </div>
                    <div className="w-[100px] mr-2">
                        {orderLine.reg_date}
                    </div>
                    <div className="w-[100px]">
                        {orderLine.order_date}
                    </div>
                    <div className="w-[70px] text-right">
                        {orderLine.km === null ? '-' : formatNumberWithSeparator(orderLine.km, '.')}
                    </div>
                </div>

                {showPickupOptions &&
                    <div>
                        {!orderLine.transport_select.locked &&
                            (
                                <OrderTransportSelection locked={false} deliveryChoice={deliveryChoice} setDeliveryChoice={setDeliveryChoice} transportOptions={orderLine.transport_select.options} saveEndPoint={orderLine.transport_select.endpoint}/>
                            )
                        }
                    </div>
                }

                {!showPickupOptions &&
                    <div className="flex items-center">
                        {orderLine.steps.map((step) => {
                            let stepColorClass = ((step.status === 'complete' || step.status === '' || step.awaiting_days === 0) && step.status !== 'awaiting') ? (isEven() ? 'bg-white' : 'bg-gray-200') : 'bg-red-200';
                            stepColorClass = step.expected !== '' && step.expected !== null && step.status !== 'complete' ? 'bg-yellow-100' : stepColorClass;
                            return (
                                <div key={'car_step_' + orderLine.hash + '_' + step.step} className={`relative w-[120px] h-[48px] flex items-center justify-center rounded px-4 py-1 mx-1 shadow ${stepColorClass}`}>
                                    <div className={`${step.status === 'complete' ? '' : 'hidden'} z-0 absolute top-0 left-[30px]`}>
                                        <div className="p-2 text-center text-gray-500 w-full h-full">
                                            {step.tt_url &&
                                                <Tooltip label="Click to open track and trace in another tab">
                                                    <a href={step.tt_url} target="_blank">
                                                        <div className="font-bold text-xl underline leading-5">{getDayOfMonth(step.date)}</div>
                                                        <div className="underline">{getMonthShortName(step.date)}-{getTwoDigitYear(step.date)}</div>
                                                    </a>
                                                </Tooltip>}
                                            {(!step.tt_url || step.tt_url === '') && (
                                                <>
                                                    <div className="font-bold text-xl leading-5">{getDayOfMonth(step.date)}</div>
                                                    <div>{getMonthShortName(step.date)}-{getTwoDigitYear(step.date)}</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${step.status !== 'complete' && step.expected !== '' && step.expected !== null ? '' : 'hidden'}`}>
                                        <div className="flex items-center justify-center w-full h-full rounded-xl p-1 text-xs">
                                            <div className="text-center">
                                                <div className="text-gray-500 font-bold">Expected</div>
                                                <div>{step.expected}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`text-red-600 ${step.status === 'awaiting' && step.awaiting_days > 0 ? '' : 'hidden'}`}>{step.awaiting_days}&nbsp;days</div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>

            {/* EXPANDED VIEW */}
            {data.length !== 0 && expandedView &&
                <div className={`border-t border-t-gray-200 bg-white w-[890px] mb-8`}>
                    <div className='flex mt-4 ml-6'>
                        <div className=''>
                            {data.documents.length > 0 &&
                                <div className="mb-6">
                                    <div className="font-bold text-sm mb-1">Order Documents</div>
                                    <div className='flex-1 text-sm max-w-[250px] min-w-[250px]'>
                                        {data.documents.map((item) => (
                                            <div key={item.file_url} className="flex mb-1">
                                                <div><img src="/images/pdf_new.png" alt="pdf document" className="w-6 mr-3"/></div>
                                                <div><a href={item.file_url} target="_blank" className="underline">{item.cars_documents_types_name}</a></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                            <div className={`my-4 ${orderLine.transport_select.options !== undefined ? '' : 'hidden'}`}>
                                <div className="font-bold text-sm mb-1">Transport options</div>
                                <OrderTransportSelection locked={orderLine.transport_select.locked} deliveryChoice={deliveryChoice} setDeliveryChoice={setDeliveryChoice} transportOptions={orderLine.transport_select.options} saveEndPoint={orderLine.transport_select.endpoint}/>
                            </div>
                            {trackNTraceAvailable &&
                                <>
                                    <div className="font-bold text-sm mb-1">Track'n trace information</div>
                                    {data.transports.length > 0 &&
                                        <>
                                            {
                                                data.transports.map((transport) => {
                                                    if (transport.tt_url !== '' && transport.tt_url !== null) {
                                                        return (
                                                            <div className="text-sm">Transport to:&nbsp;<a href={transport.tt_url} className="underline" target="_blank">{transport.to_name},&nbsp;{transport.to_address},&nbsp;{transport.to_zip_code},&nbsp;{transport.to_city}</a></div>
                                                        )
                                                    }
                                                    return null;
                                                })
                                            }
                                        </>
                                    }
                                    {data.shipments.length > 0 &&
                                        <>
                                            {
                                                data.shipments.map((shipments) => {
                                                    if (shipments.tt_url !== '' && shipments.tt_url !== null) {
                                                        return (
                                                            <div className="text-sm">Document:&nbsp;<a href={shipments.tt_url} className="underline" target="_blank">{shipments.type}&nbsp;|&nbsp;Sent: {shipments.sent_at}</a></div>
                                                        )
                                                    }
                                                    return null;
                                                })
                                            }
                                        </>
                                    }
                                    <div className="mb-6">
                                    </div>
                                </>
                            }

                            <div className='flex'>
                            <div className='flex-1 text-sm max-w-[250px] min-w-[250px]'>
                                    <CarAttribute label='Car #' value={orderLine.car_id}/>
                                    <CarAttribute label='VIN' value={orderLine.vin_no}/>
                                    <CarAttribute label='Make' value={data.cars.make_name.value}/>
                                    <CarAttribute label='Model' value={data.cars.model.value}/>
                                    <CarAttribute label='Model year' value={data.cars.model_year.value}/>
                                    <CarAttribute label='First reg.' value={data.cars.reg_date.value}/>
                                </div>
                                <div className='border-r border-l-solid w-[1px]'></div>
                                <div className='flex-1 text-sm ml-4 max-w-[250px] min-w-[250px]'>
                                    <CarAttribute label='KM' value={data.cars.km.value}/>
                                    <CarAttribute label='Color' value={data.cars.color.value === null ? data.cars.factory_color.value : data.cars.color.value}/>
                                    <CarAttribute label='Car type' value={data.cars.car_type_name.value}/>
                                    <CarAttribute label='Body type' value={data.cars.body_type_name.value}/>
                                    <CarAttribute label='Seats' value={data.cars.seats.value}/>
                                </div>
                                <div className='border-r border-l-solid w-[1px]'></div>
                                <div className='flex-1 text-sm ml-4 max-w-[250px] min-w-[250px]'>
                                    <CarAttribute label='Fuel' value={data.cars.fuel_name.value}/>
                                    <CarAttribute label='CO2 rating' value={data.cars.co2_rating_wltp.value}/>
                                    <CarAttribute label='Gear' value={data.cars.gear_name.value}/>
                                    {data.cars.battery_size.value !== null && <CarAttribute label='Battery size' value={data.cars.battery_size.value}/>}
                                    {data.cars.fuel_name.value !== 'Electric' && <CarAttribute label='Engine size' value={data.cars.engine_size.value}/>}
                                    <CarAttribute label='HP' value={data.cars.horsepower.value}/>
                                </div>
                            </div>

                            {data.cars.condition.value !== null &&
                                <div className='mt-5'>
                                    <div className='text-base font-semibold mb-3'>Condition</div>
                                    <div className='max-w-[750px] whitespace-normal'>
                                        {data.cars.condition.value}
                                    </div>
                                </div>
                            }

                            {data.equipment.length > 0 &&
                                <div className='mt-5'>
                                    <div className='text-base font-semibold mb-3'>Equipment</div>
                                    <div className='max-w-[750px] whitespace-normal'>
                                        {data.equipment.map((item) => (
                                            <span key={item.name} className='mb-[10px] whitespace-nowrap mr-2 px-2 py-1 rounded bg-gray-200 inline-block text-sm' style={{lineHeight: '15px'}}>{item.name}</span>
                                        ))}
                                    </div>
                                </div>
                            }

                            <div className='flex flex-wrap items-center my-4'>
                                <CarImageModal images={data.images.all} visible={showImageModal} setVisible={setShowImageModal} imageIndex={showImageIndex}/>
                                <CarNavigationThumbs images={data.images.all} maxImagesNumber={20} onClick={handleThumbnailClick}/>
                            </div>

                        </div>
                    </div>

                </div>
            }
        </div>
    )
}