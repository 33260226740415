import React, {useEffect, useState} from "react";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {VATIcon} from "./VATIcon";
import {CarStatusPill} from "./CarStatusPill";
import {CarAttributeWithIcon} from "./CarAttributeWithIcon";
import {AddToBasketButton} from "../ShoppingBasket/AddToBasketButton";
import {CarAttribute} from "./CarAttribute";
import {CarImageModal} from "./CarImageModal";
import {CarNavigationThumbs} from "./CarNavigationThumbs";
import {axiosInstance} from "../../Api/AxiosConfig";
import {Carousel} from "@mantine/carousel";
import {DocumentIcon} from "@heroicons/react/24/outline";

export const CarMobileCard = ({car, fnUpdate, scrollIntoView, selectedCarId, setSelectedCarId, showPrice = true}) => {
    const [expandedView, setExpandedView] = useState(false);
    const [data, setData] = useState([]);
    const [showImageIndex, setShowImageIndex] = useState(0);

    useEffect(() => {
        if (expandedView) {
            axiosInstance
                .get(car.info_endpoint)

                .then((response) => {
                    setData(response.data);
                    scrollIntoView(("car_mobile_card_" + car.hash));
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedView]);

    const handleThumbnailClick = (index) => {
        setShowImageIndex(index);
    }

    useEffect(() => {
        if (car.hash !== selectedCarId) {
            setExpandedView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCarId]);

    useEffect(() => {
        if (car.hash === selectedCarId) {
            setExpandedView(true);
        }
    }, [selectedCarId]);

    return (
        <div className="relative rounded-xl bg-white p-4 mb-4 shadow cursor-pointer">
            <div className="active:bg-blue-100" onClick={() => {
                setExpandedView(!expandedView);
                setSelectedCarId(car.hash)
            }}>
                <div className={'absolute pointer-events-none top-0 left-0 flex rounded-xl items-center justify-center bg-purchase-color opacity-20 w-full h-full ' + (car.in_basket ? ' block ' : ' hidden ')}>
                    <span className='text-3xl font-bold'></span>
                </div>
                <div className={'absolute top-0 left-0 flex rounded-lg items-center justify-center bg-white opacity-80 w-full h-full pointer-events-none ' + (car.is_sold === 1 ? ' block ' : ' hidden ')} onClick={(e) => e.stopPropagation()}>
                    <span className='text-3xl font-bold'>SOLD</span>
                    <span className="material-symbols-outlined text-[40px] ml-4">eco</span>
                </div>
                <div className={`absolute top-3 left-3 rounded-xl py-1 px-3 bg-purchase-color text-xs text-white ${car.in_basket !== null ? '' : 'hidden'}`}>
                    In Basket
                </div>
                <div className="w-full flex justify-center">
                    <img className='rounded-full w-[80px] h-[80px] bg-white object-scale-down shadow' src={car.list_image} alt={'image for ' + car.designation}/>
                </div>
                <div className={(car.buy_status === 'confirmed' && !car.is_sold ? '' : 'hidden') + ' absolute bottom-3 right-3'}>
                    <CarStatusPill title='Confirmed' bgColor='bg-confirmed' className='text-xs'/>
                </div>
                <div className={(car.buy_status === 'fast_delivery' && !car.is_sold ? '' : 'hidden') + ' absolute bottom-3 right-3'}>
                    <CarStatusPill title='Fast Delivery' bgColor='bg-fast-delivery' className='text-xs'/>
                </div>
                <div className={(car.buy_status === 'listing' && !car.is_sold ? '' : 'hidden') + ' absolute bottom-3 right-3'}>
                    <CarStatusPill title='Reservable' bgColor='bg-reservable' className='text-xs'/>
                </div>
                <div className={(car.new_on_list === 'true' ? '' : 'hidden') + ' absolute left-3 bottom-3'}>
                    <CarStatusPill title='New' bgColor='bg-new-on-list' className='text-xs'/>
                </div>
                <div className={(car.price_info !== "" && car.price_info !== undefined ? '' : 'hidden') + ' absolute left-3 bottom-3'}>
                    <CarStatusPill title={car.price_info} bgColor='bg-new-on-list' className='text-xs'/>
                </div>

                <div className="flex justify-center font-bold px-8 text-center mt-3">{car.designation},&nbsp; {formatNumberWithSeparator(car.km, '.') + ' km'}</div>
                <div className="flex items-center justify-center font-bold text-xl text-purchase-color" style={{display: showPrice ? 'flex' : 'none'}}>
                    <div>{formatNumberWithSeparator(parseInt(car.price), '.')}&nbsp;{car.currency}</div>
                    <div className='flex items-center ml-2'><VATIcon vatStatusId={car.vat_status_id}/></div>
                </div>
                <div className="flex justify-center text-sm text-center mt-2">{car.info_line_1}</div>
                <div className="w-full flex justify-center mt-6">
                    <AddToBasketButton hash={car.hash} fnUpdate={fnUpdate} carInBasket={car.in_basket} className="w-[150px]" text="Add to basket"/>
                </div>
            </div>

            {/* EXPANDED VIEW */}
            {data.length !== 0 && expandedView &&
                <div className={`border-t border-t-gray-200 mt-8`}>
                    <div className="ml-6 mt-6 font-bold">{car.designation}</div>
                    <div className='flex mt-4 ml-6'>
                        <div className=''>
                            <div className=''>
                                <div className='text-sm'>
                                    <CarAttribute label='Car #' value={car.car_id}/>
                                    <CarAttribute label='VIN' value={data.cars.vin_no.value}/>
                                    <CarAttribute label='Make' value={data.cars.make_name.value}/>
                                    <CarAttribute label='Model' value={data.cars.model.value}/>
                                    <CarAttribute label='Model year' value={data.cars.model_year.value}/>
                                    <CarAttribute label='First reg.' value={data.cars.reg_date.value}/>
                                </div>
                                <div className='border-b border-dashed  h-[1px] mt-2 mb-2'></div>
                                <div className='text-sm'>
                                    <CarAttribute label='KM' value={data.cars.km.value}/>
                                    <CarAttribute label='Color' value={data.cars.color.value === null ? data.cars.factory_color.value : data.cars.color.value}/>
                                    <CarAttribute label='Car type' value={data.cars.car_type_name.value}/>
                                    <CarAttribute label='Body type' value={data.cars.body_type_name.value}/>
                                    <CarAttribute label='Seats' value={data.cars.seats.value}/>
                                </div>
                                <div className='border-b border-dashed  h-[1px] mt-2 mb-2'></div>
                                <div className='text-sm'>
                                    <CarAttribute label='Fuel' value={data.cars.fuel_name.value}/>
                                    <CarAttribute label='CO2 rating' value={data.cars.co2_rating_wltp.value}/>
                                    <CarAttribute label='Gear' value={data.cars.gear_name.value}/>
                                    {data.cars.battery_size.value !== null && <CarAttribute label='Battery size' value={data.cars.battery_size.value}/>}
                                    {data.cars.fuel_name.value !== 'Electric' && <CarAttribute label='Engine size' value={data.cars.engine_size.value}/>}
                                    <CarAttribute label='HP' value={data.cars.horsepower.value}/>
                                </div>
                                <div className='border-b border-dashed  h-[1px] mt-2 mb-2'></div>
                            </div>

                            <div className="mt-4">
                                {data.documents.length > 0 &&
                                    <div className="mb-4">
                                        <div className="font-bold text-sm mb-1">Documents</div>
                                        <div className='flex-1 text-sm max-w-[250px] min-w-[250px]'>
                                            {data.documents.map((item) => (
                                                <div key={item.file_url} className="flex mb-1">
                                                    <div><img src="/images/pdf_new.png" alt="pdf document" className="w-6 mr-3"/></div>
                                                    <div><a href={item.file_url} target="_blank" className="underline">{item.cars_documents_types_name}</a></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>

                            {data.dkPrice.status &&
                                <div className='' style={{display: showPrice && !car.is_sold ? 'block' : 'none'}}>
                                    <div className="mt-6 font-bold">Suggested Price Calculation</div>
                                    <div className="flex mt-4">
                                        <div className='max-w-[750px] whitespace-normal'>
                                            <div className="flex items-center justify-between">
                                                <div className='text-sm'>Price</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.dk_price), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className='text-sm'>Tax</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.dk_tax_amount), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between border-b border-b-solid ">
                                                <div className='text-sm'>VAT</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.vat_amount), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between mt-2">
                                                <div className='text-sm'>DK Total Net Price</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.total_price), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className='text-sm'>DK suggested retail price</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.potential_tender_price), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between mt-2 border-b border-dashed border-t">
                                                <div className='text-sm'>Potential Profit</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.profit), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            {data.dkPrice.tax_document &&
                                                <div className="mt-4">
                                                    <div className='text-sm flex items-center'><DocumentIcon className="w-4 mr-2"/><a href={data.dkPrice.tax_document} className="text-sm underline" target="_new">Tax calculation documentation</a></div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {data.cars.condition.value !== null &&
                                <div className='mt-5'>
                                    <div className='text-base font-semibold mb-3'>Condition</div>
                                    <div className='max-w-[750px] whitespace-normal'>
                                        {data.cars.condition.value}
                                    </div>
                                </div>
                            }

                            {data.equipment.length > 0 &&
                                <div className='mt-5'>
                                    <div className='text-base font-semibold mb-3'>Equipment</div>
                                    <div className='whitespace-normal'>
                                        {data.equipment.map((item) => (
                                            <span key={item.name} className='mb-[10px] whitespace-nowrap mr-2 px-2 py-1 rounded bg-gray-200 inline-block text-sm' style={{lineHeight: '15px'}}>{item.name}</span>
                                        ))}
                                    </div>
                                </div>
                            }

                            <div className='flex flex-wrap items-center my-4'>
                                <CarNavigationThumbs images={data.images.all} maxImagesNumber={20} onClick={handleThumbnailClick}/>
                            </div>

                            <div className="mt-4 mb-8" style={{display: data.images.all.length > 0 ? 'block' : 'none'}}>
                                <Carousel withIndicators initialSlide={showImageIndex} classNames={{control: 'text-white'}}>
                                    {data.images.all.map((image, index) => (
                                        <Carousel.Slide key={'large_image_' + image.file_url}>
                                            <img id={'large_image_' + image.file_url} src={image.file_url} className='rounded object-cover w-full' alt='Car'/>
                                        </Carousel.Slide>
                                    ))}
                                </Carousel>
                            </div>

                        </div>
                    </div>

                </div>
            }
        </div>
    )
}