import React, {useEffect, useState} from "react";
import {Switch} from "@mantine/core";
import {InitControlValueData} from "./CarFilterBar";

export const CarFilterMobile = ({fnFilterChange}) => {
    const [controlValueData, setControlValueData] = useState(InitControlValueData);
    const changeFilterValue = (value, elementName) => {
        setControlValueData(prevState => ({
            ...prevState, [elementName]: value
        }));
    }

    useEffect(() => {
        fnFilterChange(controlValueData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlValueData]);

    return (
        <>
            <div className="mr-3 py-1 pr-2 border-b-gray-300"><Switch checked={controlValueData.in_basket} labelPosition='left' label='In Basket' onChange={(event) => changeFilterValue(event.currentTarget.checked, 'in_basket')} className='whitespace-nowrap' color='blue' classNames={{thumb: 'bg-blue-500', track: 'bg-white'}}/></div>
            <div className="mr-3 py-1 pr-2 border-b-gray-300"><Switch checked={controlValueData.buy_status} labelPosition='left' label='Fast Delivery' onChange={(event) => changeFilterValue(event.currentTarget.checked, 'buy_status')} className='whitespace-nowrap' color='blue' classNames={{thumb: 'bg-blue-500', track: 'bg-white'}}/></div>
        </>
    )
}