import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Home} from './Pages/Home';
import {NotFound404} from "./Pages/Messages/NotFound404";
import {LoggedOut} from "./Pages/Messages/LoggedOut";
import {Login} from "./Pages/Security/Login";
import './App.css';
import './input.css';
import DataProvider from "./GlobalState/DataProvider";
import {TailwindUi} from "./Pages/Experiments/TailwindUi";
import {OrderReceived} from "./Pages/Messages/OrderReceived";
import {DailyOverview} from "./Pages/Management/Dashboards/DailyOverview";
import {createTheme, MantineProvider} from "@mantine/core";
import {getPusherCluster} from "./Hooks/UseBaseData";
import {ChangePassword} from "./Pages/Security/ChangePassword";
import {ForgottenPassword} from "./Pages/Security/ForgottenPassword";

function App() {
    const setupGlobalData = () => {
        return {
            userData: null
        };
    };

    const theme = createTheme({
        /** Put your mantine theme override here */
    });

    useEffect(() => {
        if (getPusherCluster() === null && window.location.pathname !== '/login' && window.location.pathname !== '/forgottenpassword' && window.location.pathname !== '/changepassword') {
            window.location = '/login';
        }
    }, []);

    return (
        <DataProvider value={setupGlobalData()}>
            <MantineProvider theme={theme}>
                <div className="App h-screen w-screen">

                    <Router>
                        <Routes>
                            <Route path="/" exact element={<Home menuItemSelected={1}/>}/>
                            <Route path="/orderreceived" element={<OrderReceived/>}/>
                            <Route path="/experiment" element={<TailwindUi/>}/>
                            <Route path="/checkout" element={<Home menuItemSelected={2}/>}/>
                            <Route path="/orders" exact element={<Home menuItemSelected={3}/>}/>
                            <Route path="/vendororders" element={<Home menuItemSelected={4}/>}/>
                            <Route path="/terms" element={<Home menuItemSelected={5}/>}/>
                            <Route path="/ordersnew" element={<Home menuItemSelected={6}/>}/>
                            <Route path="/loggedout" element={<LoggedOut/>}/>
                            <Route path="/managementdashboard" element={<DailyOverview/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/changepassword" element={<ChangePassword/>}/>
                            <Route path="/forgottenpassword" element={<ForgottenPassword/>}/>
                            <Route path="*" element={<NotFound404/>}/>
                        </Routes>
                    </Router>

                </div>
            </MantineProvider>
        </DataProvider>
    );
}

export default App;
