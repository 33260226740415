export const formatNumberWithSeparator = (number, separator) => {
    if (typeof number !== 'number') {
        return 'Invalid input';
    }

    let numberString = number.toString();

    const regex = /\B(?=(\d{3})+(?!\d))/g;
    numberString = numberString.replace(regex, separator);

    return numberString;
}

export const registerUserActivity = (axiosInstance, functionId, comment) => {
    axiosInstance
        .post('/shop/stats', {
            function_id: functionId,
            function_text: comment
        })
}

export const getFirstXCharacters = (string, x) => {
    if (typeof string !== 'string') {
        return 'Invalid input';
    }

    return string.substring(0, x);
}

export const valueIfNull = (value, defaultValue) => {
    if (value === undefined || value === null) {
        return defaultValue;
    }
    return value;
}

// function that takes a number and returns a string. If the number is below 10 then put a 0 in front of it
export const formatNumberWithLeadingZero = (number) => {
    if (typeof number !== 'number') {
        return 'Invalid input';
    }

    return number < 10 ? `0${number}` : number.toString();
}

export const isEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return regex.test(email);
};

export const setCookie = (name, value, daysToExpire) => {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }
    return null; // Cookie not found
}

export const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
}

export const getFromLocalStorage = (key) => {
    return localStorage.getItem(key);
}

export const EnsureNoneBreakingSpace = (text) => {
    return text.replace(/ /g, '\u00a0');
}

export const getFormattedDate = (date, separator) => {
    const day = formatNumberWithLeadingZero(date.getDate());
    const month = formatNumberWithLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}${separator}${month}${separator}${year}`;
}

export const getDayOfMonth = (date) => {
    return date.split('-')[0];
}

export const getMonth = (date) => {
    return date.split('-')[1];
}

export const getYear = (date) => {
    return date.split('-')[2];
}

export const getTwoDigitYear = (date) => {
    return date === '' ? '' : date.split('-')[2].substring(2);
}

export const getMonthName = (date) => {
    const month = getMonth(date);
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return monthNames[month - 1];
}

export const getMonthShortName = (date) => {
    const month = getMonth(date);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return monthNames[month - 1];
}

export const scrollToId = (bookmarkName) => {
    const element = document.getElementById(bookmarkName);
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
}

export const DeleteUrlParameter = (parameterName) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(parameterName);
    window.history.replaceState({}, '', url);
}

// Function for smooth scroll animation with the time duration
export const easeInOutQuad = (time, startPos, endPos, duration) => {
    time /= duration / 2;

    if (time < 1) return (endPos / 2) * time * time + startPos;
    time--;
    return (-endPos / 2) * (time * (time - 2) - 1) + startPos;
}

export const scrollTo = (element, to, duration) => {
    let start = element.scrollTop,
        currentTime = 0,
        increment = 20;

    let animateScroll = function () {
        currentTime += increment;

        let val = easeInOutQuad(currentTime, start, to, duration);
        element.scrollTop = val;

        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };

    animateScroll();
}

// This is the main function where which pass two ref parameters of Parent element & Child element
export const scrollIntoViewUniversal = (parent, child) => {
    const parentBounding = parent.getBoundingClientRect(),
        clientBounding = child.getBoundingClientRect();

    const parentBottom = parentBounding.bottom,
        parentTop = parentBounding.top,
        clientBottom = clientBounding.bottom,
        clientTop = clientBounding.top;

    if (parentTop >= clientTop) {
        scrollTo(parent, -(parentTop - clientTop), 300);
    } else if (clientBottom > parentBottom) {
        scrollTo(parent, clientBottom - parentBottom, 300);
    }
};

// Get the value of the query parameter from the URL and return a specified value if it is not found
export const getUrlParameter = (name, defaultValue) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name) || defaultValue;
}