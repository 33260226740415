import React from "react";
import {Tooltip} from "@mantine/core";

export const CarStatusPill = ({title, explanation, bgColor, className}) => {
    return (
        <Tooltip label={explanation} position='bottom' withArrow>
            <span className={'py-1 px-2 text-white text-center rounded whitespace-nowrap ' + bgColor + ' ' + className}>
                {title}
            </span>
        </Tooltip>
    )
}