export const ExplicitCssClasses = () => {
    // This is to make sure that tailwind classes that are used in variables are also generated in the output css file
    return (<div className='
         hidden
         fast-delivery
         reservable
         line-through
         shadow-lg
         w-[50px]
         text-black
         text-white
         shadow-xl
         animate-pulse
         ml-4
         text-gray-500
         text-xs
         leading-5
         bg-gray-300
         bg-gray-200
         '></div>)
}