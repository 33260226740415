import React from "react";
import {WidgetContainer} from "../../../Components/Dashboard/WidgetContainer";

export const DailyOverview = () => {
    return (
        <div className='bg-black flex min-h-screen flex-col items-center justify-between p-24'>
            <div className="m-auto text-center">
                <h1 className="font-semibold text-6xl text-white">
                    Carzelle<span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-sky-400 opacity-75"></span>
                </h1>
            </div>
            <div className="m-auto text-center">
                <WidgetContainer title='Latest sales' className='mb-4' />
                <WidgetContainer title='Daily Overview' className='mb-4' />
            </div>
        </div>
    )
}