import React, {useEffect, useRef, useState} from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {useLocation, useParams} from "react-router-dom";
import {ShieldCheckIcon} from "@heroicons/react/24/outline";
import {InlineMessage} from "../../Components/System/Messages/InlineMessage";

export const ChangePassword = () => {
    const [errorTextPassword, setErrorTextPassword] = useState('');
    const [errorTextPasswordRepeat, setErrorTextPasswordRepeat] = useState('');
    const location = useLocation();
    const [changePwdToken, setChangePwdToken] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setChangePwdToken(searchParams.get("token"));
    }, []);

    const DoChangePassword = () => {
        axiosInstance.post('auth/update-password', {
            token: changePwdToken,
            password: password
        })
            .then(function (response) {
                if (response.status !== 200) {
                    setErrorTextPassword(response.statusText);
                } else {
                    setPasswordChanged(true);
                }
            })
    }

    const passwordIsReady = () => {
        return (password === passwordRepeat && password.length >= 8);
    }

    useEffect(() => {
        if (passwordRepeat !== password) {
            setErrorTextPasswordRepeat('Passwords do not match');
        }
        else {
            setErrorTextPasswordRepeat('');
        }
    }, [password, passwordRepeat]);

    if (changePwdToken && !passwordChanged) {
        return (
            <>
                <div className="overflow-auto h-screen w-screen relative flex items-center justify-center">
                    <div className='screen-small:w-[900px] h-[650px] rounded-3xl bg-white mx-2 shadow-lg flex items-center z-10'>
                        <div className='mx-2 h-[650px] screen-small:w-[450px] bg-white rounded-3xl screen-small:rounded-l-3xl screen-small:rounded-r-none'>
                            <div className='w-full mt-[50px]'><img src="/images/logo.png" className="m-auto min-w-[200px] max-w-[200px]" alt='Broken down car'/></div>
                            <div className='mx-[70px] mt-[40px] font-open-sans'>
                                <div className='text-gradient text-info login-header mb-1'>Change password</div>
                                <div className='text-[16px] font-normal mb-[25px]'>Enter your new password (minimum 8 characters, capital and non capital letters and one or more numbers)</div>
                                <label className='login-label'>New Password
                                    <div className='h-[50px] flex items-center'>
                                        <input id='password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='login-input w-full' autoFocus={true} placeholder='Password'/>
                                    </div>
                                    {errorTextPassword && <div className='text-red-400 text-sm'>{errorTextPassword}</div>}
                                </label>
                                <label className='login-label'>Repeat Password
                                    <div className='h-[50px] flex items-center'>
                                        <input id='passwordrepeat' type="password" value={passwordRepeat} onChange={(e) => setPasswordRepeat(e.target.value)} className='login-input w-full' placeholder='Password'/>
                                    </div>
                                    {errorTextPasswordRepeat && <div className='text-red-400 text-sm'>{errorTextPasswordRepeat}</div>}
                                </label>
                                <button className={`w-full mb-4 mt-4 ${passwordIsReady() ? 'blue-gradient-button' : 'gray-gradient-button'}`} onClick={() => DoChangePassword()} disabled={!passwordIsReady()}>Set new Password</button>
                                <div className='text-sm w-full text-center tracking-tighter font-medium text-gradient text-info'><a href='mailto:info@carzelle.com'>info@carzelle.com</a></div>
                            </div>
                        </div>

                        <div className='w-[450px] h-[650px] rounded-r-3xl login-header-background flex justify-center relative hidden screen-small:flex'>
                            <div className="flex items-center justify-center">
                                <div><ShieldCheckIcon className="w-48 opacity-20" /></div>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        )
    }

    if (passwordChanged) {
        return (
            <InlineMessage title="Password was changed!" explanation="You can now login with your new password" leadingText="" followLink="/login" followLinkText="Click here to go to login page"/>
        )
    }

    if (!changePwdToken && !passwordChanged)    {
        return (
            <>
                <div className="overflow-auto h-screen w-screen flex items-center justify-center">
                    <div className="m-8">
                        <div className="text-6xl font-bold text-center text-gradient text-info mb-16">No token found</div>
                        <img src="/images/carbrokendown.png" alt="Car broken down" className="m-auto max-w-[250px] screen-small:max-w-[500px] mb-4"/>
                        <div className="text-3xl text-center text-gray-500">You cannot change or set your password</div>
                    </div>
                </div>
            </>
        )
    }
}