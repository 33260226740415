import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import React from "react";

export const CheckoutLinesMobile = ({lines, title, discountType}) => {

    if (lines.length !== 0) {
        return (
            <>
                <div className='text-lg font-bold text-gray-800 mt-4'>{title}</div>

                {lines.map((item) => (
                    <div key={'item_' + item.basketHash}>
                        <div className={'flex bg-white px-4 py-2 rounded-xl mt-4 text-sm items-center justify-between relative ' + (item.is_sold === 1 ? ' line-through' : '')}>
                            <div className={'absolute top-0 right-0 flex justify-center bg-gray-200 w-[140px] px-2 rounded text-white ' + (item.is_sold === 1 ? '' : 'hidden')}>Sold</div>

                            <div className="absolute top-0 w-full">
                                <div className="flex justify-end mr-4">
                                    <img className='absolute top-[-25px] rounded-full w-[50px] h-[50px] bg-white object-scale-down shadow' src={item.list_image} alt={'image for ' + item.designation}/>
                                </div>
                            </div>

                            <div className=''>
                                <div className='font-bold'>
                                    {item.cars_designation}
                                </div>
                                <div className=''>
                                    <span className="font-medium">VIN:</span>{item.cars_vin_no}
                                </div>
                                <div className='mr-8'>
                                    <span className="font-medium">KM:</span>{item.cars_km === null ? '' : formatNumberWithSeparator(item.cars_km, '.')}
                                </div>
                                <div className=''>
                                    {item.cars_vat_status === 4 ? 'VAT\u00A0Incl.' : 'Ex.\u00A0VAT'}
                                </div>
                            </div>
                            <div>
                                <div style={{display: discountType === null ? 'block' : 'none'}}>{formatNumberWithSeparator(parseInt(item.price), '.')}&nbsp;{item.currency}</div>
                                <div style={{display: discountType === 1 ? 'block' : 'none'}}>{formatNumberWithSeparator(parseInt(item.level_1), '.')}&nbsp;{item.currency}</div>
                                <div style={{display: discountType === 2 ? 'block' : 'none'}}>{formatNumberWithSeparator(parseInt(item.level_2), '.')}&nbsp;{item.currency}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )
    } else {
        return null;
    }
}