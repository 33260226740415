export const Terms = ({selected}) => {

    const informationPoint = (title, text) => {
        const textWithNewline = text.split('\n').map((item, key) => (
            <span key={key}>{item}<br /></span>
        ));
        return (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-bold text-gray-900">{title}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{textWithNewline}</dd>
            </div>
        )
    }

    return (
        <div className=''>
            <div className="overflow-hidden screen-small:bg-white screen-small:shadow sm:rounded-lg max-w-[900px] mx-5 bg-background-color screen-small:my-[50px] screen-small:ml-[50px]">
                <div className="px-4 py-6 sm:px-6">
                    <h3 className="text-2xl font-semibold leading-7 text-gray-900">Terms</h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Terms of service</p>
                    <div className='mt-4'>
                        Welcome to Carzelle Shop, dedicated exclusively to CarDealers. Prior to utilizing our platform, we kindly ask you to thoroughly review the following terms. Your use of our services signifies your agreement to be bound by these terms.
                    </div>
                </div>
                <div className="border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        {informationPoint('Fast Delivery', 'Cars labeled as "Fast Delivery" are currently in stock at one of our facilities and are available for immediate delivery.')}
                        {informationPoint('Confirmed', 'Cars labeled as "Confirmed" are verified, and Carzelle A/S is the current owner.')}
                        {informationPoint('Reservable', 'Cars labeled as "Reserveable" are presented in collaboration with a third party. Carzelle A/S will acquire these cars upon your confirmation of the deal on our site. Availability is subject to change, and we make every effort to display cars that are reserved for us.')}
                        {informationPoint('Payment', 'We will invoice the cars to you once they are confirmed. Payment terms require settlement within 3 days after receiving notification that the car is in stock and ready for pickup/delivery.')}
                        {informationPoint('Cancellation', 'All orders are considered final. In the event a buyer wishes to cancel a purchase, the invoice for the car must still be paid. Carzelle will assist in reselling the car, and if the resale price is lower than the original, the difference will be deducted when refunding the money to the original buyer.')}
                        {informationPoint('Seller and Owner', 'Carzelle A/S\nBakketoften 3\n 8541 Skødstrup\nDenmark\nVAT: DK44210223\ninfo@carzelle.com')}
                        {informationPoint('Cosmetic Condition', 'The cosmetic condition of cars is detailed in each listing, with damages possibly depicted in photos. Up to 450 EUR is accepted on all cars if no amount is entered. If a vehicle does not meet your expectations, please send photos of any damage to info@carzelle.com.')}
                        {informationPoint('COC Document', 'A Certificate of Conformity (COC) document is included for all cars.')}
                        {informationPoint('Second Key', 'A second key is included for all cars.')}
                        {informationPoint('Service / Maintenance', 'We strive to provide documentation for service/maintenance, but there is no guarantee that we can supply this.')}
                        {informationPoint('Cars with equipment not accurate as mentioned in the listing', 'In the event that the equipment described in the listing is not accurate upon receipt of the car, Carzelle will offer you a full refund and have the car returned or provide a discount in alignment with the expected difference in the wholesale price that the equipment could have.')}
                        {informationPoint('Transport for non-Danish Buyers', 'Buyers can pick up cars at the stated location or request transport to their location. Please inquire for pricing.')}
                        {informationPoint('Transport for Danish Buyers', 'Buyers can pick up cars at the specified location, or we can offer transport to your location at these prices:\n' +
                            '\n' +
                            '    Jylland / Small Cars: 600 DKK / Large Cars: 900 DKK\n' +
                            '    Zealand / Small Cars: 900 DKK / Large Cars: 1200 DKK')}
                        {informationPoint('Posting Cars for Sale before buying from Carzelle', 'You are not allowed to post Carzelle listed Cars on any marketplace before purchasing the cars.')}
                        {informationPoint('Suggested Price Calculation', 'The prices provided are applicable solely for the Danish market. Carzelle does not assume any responsibility for the accuracy or reliability of the suggested prices provided. It is the responsibility of the user to independently verify the suggested prices and any associated taxes or fees before making any purchasing decisions.')}
                        {informationPoint('Liability', 'Carzelle cannot be held accountable for unexpected delays or issues that may arise during the car acquisition process. We encourage you to exercise reasonable care and judgment in all interactions and transactions. By using Carzelle Shop, you agree to these terms. If you have any questions or concerns, please contact us at info@carzelle.com.')}
                        {informationPoint('VAT (Value Added Tax)', 'Cars sold to buyers outside Denmark will be sold excluding VAT (NET), unless the car has already paid VAT.')}
                        {informationPoint('Legal Jurisdiction', 'Any legal matters shall be resolved in the Court of Aarhus, Denmark.')}
                    </dl>
                </div>
            </div>
            <div className='h-[100px]' ></div>
        </div>
    )
}