import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import React from "react";

export const CheckoutLines = ({lines, title, discountType}) => {
    if (lines.length !== 0) {

        return (
            <>
                <div className='text-lg font-bold text-gray-800 mt-4'>{title}</div>

                <div className='flex mt-4 text-sm items-center justify-between text-sm uppercase text-gray-500 font-extrabold border-b border-gray-200 pb-2'>
                    <div className='flex'>
                        <div className='w-[200px]'>
                            VIN
                        </div>
                        <div className='w-[400px]'>
                            Item
                        </div>
                        <div className='w-[50px] text-right mr-8'>
                            Km
                        </div>
                        <div className='w-[50px] text-right'>
                            VAT
                        </div>
                    </div>
                    <div>
                        PRICE
                    </div>
                </div>

                {lines.map((item) => (
                    <div key={'item_' + item.basketHash}>
                        <div className={'flex mt-4 text-sm items-center justify-between relative ' + (item.is_sold === 1 ? ' line-through' : '')}>
                            <div className={'absolute top-0 right-0 flex justify-center bg-gray-200 w-[140px] px-2 rounded bg-red-700 text-white ' + (item.is_sold === 1 ? '' : 'hidden')}>Sold</div>
                            <div className='flex'>
                                <div className='w-[200px]'>
                                    {item.cars_vin_no}
                                </div>
                                <div className='w-[400px]'>
                                    {item.cars_designation}
                                </div>
                                <div className='w-[50px] text-right mr-8'>
                                    {item.cars_km === null ? '' : formatNumberWithSeparator(item.cars_km,'.')}
                                </div>
                                <div className='w-[50px] text-right'>
                                    {item.cars_vat_status === 4 ? 'VAT\u00A0Incl.' : 'Ex.\u00A0VAT'}
                                </div>
                            </div>
                            <div>
                                <div style={{display: discountType === null ? 'block' : 'none'}}>{formatNumberWithSeparator(parseInt(item.price), '.')}&nbsp;{item.currency}</div>
                                <div style={{display: discountType === 1 ? 'block' : 'none'}}>{formatNumberWithSeparator(parseInt(item.level_1), '.')}&nbsp;{item.currency}</div>
                                <div style={{display: discountType === 2 ? 'block' : 'none'}}>{formatNumberWithSeparator(parseInt(item.level_2), '.')}&nbsp;{item.currency}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )
    } else {
        return null;
    }
}