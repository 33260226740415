import {CheckBadgeIcon} from "@heroicons/react/24/solid";
import * as PropTypes from "prop-types";
import React from "react";

export const InlineMessage = ({title, leadingText, emphasisText, explanation, followLink, followLinkText}) => {
    return <>
        <div className="h-screen w-screen flex items-center justify-center message-background">
            <div className="relative m-[5px] max-w-[500px] bg-white rounded-2xl p-10 shadow-lg border-primary-color ">
                <div className="mb-4 absolute top-[-40px] left-0 w-full">
                    <CheckBadgeIcon className="w-20 text-[#666666] m-auto"/>
                </div>
                <div className="text-gray-400">
                    <div className="text-4xl font-bold text-center text-gradient text-info mt-4 mb-8">{title}</div>
                    {leadingText && <div className="text-2xl text-center">{leadingText}</div>}
                    {emphasisText && <div className="text-2xl text-center font-bold">{emphasisText}</div>}
                    {explanation && <div className="text-base text-center mt-8 px-2">{explanation}</div>}
                    {followLink && <div className="underline text-center mt-4"><a href={followLink}>{followLinkText}</a></div>}
                </div>
            </div>
        </div>

    </>;
}

InlineMessage.propTypes = {title: PropTypes.string, leadingText: PropTypes.string, emphasisText: PropTypes.string, explanation: PropTypes.string, followLink: PropTypes.string, followLinkText: PropTypes.string};