import {Switch} from "@mantine/core";
import React from "react";
import {forwardRef} from 'react';

export const ShowroomMode = forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <Switch checked={props.value} labelPosition='left' label='Showroom' onChange={(event) => props.changeValue(event.currentTarget.checked)} className='' color='blue' classNames={{thumb: 'bg-blue-500', track: 'bg-white'}}/>
        </div>
    )
});