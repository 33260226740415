import {useEffect, useState} from "react";

export const BrandPill = ({id, imageSrc, title, active, onChange}) => {
    const [activeState, setActiveState] = useState(active);

    useEffect(() => {
        onChange(activeState ? id.toString() : null);
    }, [activeState]);
    return (
        <div onClick={() => setActiveState(!activeState)} className={`relative flex items-center justify-center rounded-xl cursor-pointer hover:scale-[1.1] transition duration-150 ease-in-out px-2 py-2 bg-white border border-gray-300'}`}>
            <div className="absolute top-0 right-0 rounded-xl opacity-25 bg-green-500 w-full h-full" style={{display: activeState ? 'block' : 'none'}}/>
            <div className="mr-1" style={{display: imageSrc === null ? 'none': 'block'}}>
                <img src={imageSrc} alt={title} className="h-4"/>
            </div>
            <div className="text-xs uppercase">
                {title}
            </div>
        </div>
    )
}