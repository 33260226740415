import React, {useEffect, useRef, useState} from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {isEmail} from "../../Hooks/UseGenericFunctions";
import {CheckBadgeIcon} from "@heroicons/react/24/solid";
import * as PropTypes from "prop-types";
import {InlineMessage} from "../../Components/System/Messages/InlineMessage";

export const ForgottenPassword = () => {
    const [errorText, setErrorText] = useState('');
    const [emailAquired, setEmailAquired] = useState(false);
    const [email, setEmail] = useState('');

    const SendEmail = () => {
        axiosInstance.post('auth/reset-password', {
            email: email,
            password: email
        })
            .then(function (response) {
                if (response.status !== 200) {
                    setErrorText('Wrong pin code or e-mail address');
                } else {
                    setEmailAquired(true);
                }
            })
    }

    if (!emailAquired) {
        return (
            <>
                <div className="overflow-auto h-screen w-screen relative flex items-center justify-center">
                    <div className='screen-small:w-[900px] h-[650px] rounded-3xl bg-white mx-2 shadow-lg flex items-center z-10'>
                        <div className='mx-2 h-[650px] screen-small:w-[450px] bg-white rounded-3xl screen-small:rounded-l-3xl screen-small:rounded-r-none'>
                            <div className='w-full mt-[50px]'><img src="/images/logo.png" className="m-auto min-w-[200px] max-w-[200px]" alt='Carzelle logo'/></div>
                            <div className='mx-[70px] mt-[40px] font-open-sans'>
                                <div className='text-gradient text-info login-header mb-1'>Reset Password</div>
                                <div className='text-[16px] font-normal mb-[5px]'>Enter your email and click Aquire new Password</div>
                                <div className='text-[12px] font-normal mb-[25px]'>You will receive an email if you have a user in our system. You then click the link in the email and reset your password</div>
                                <label className='login-label'>Email
                                    <div className='h-[50px] flex items-center screen-small:mb-[10px]'>
                                        <input id='email' type="email" autoFocus={true} className='login-input w-full' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    {errorText && <div className='text-red-400 text-sm'>{errorText}</div>}
                                </label>
                                <button className={`w-full mb-4 mt-4 ${isEmail(email) ? 'blue-gradient-button' : 'gray-gradient-button'}`} onClick={() => SendEmail()} disabled={!isEmail(email)}>Aquire new Password</button>
                                <div className='text-sm w-full text-center tracking-tighter font-medium text-gradient text-info'><a href='mailto:info@carzelle.com'>info@carzelle.com</a></div>
                            </div>
                        </div>

                        <div className='w-[450px] h-[650px] rounded-r-3xl login-header-background flex justify-center relative hidden screen-small:flex'>
                            <div className="flex items-center justify-center">
                                <div><img src="/images/lock.png" className="w-[150px] opacity-20" alt="lock"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <InlineMessage title="Email was sent!" emphasisText={email} explanation="Check your email and follow the instructions" leadingText="Sent to" followLink="/login" followLinkText=""/>
        )
    }
}