import {BrandPill} from "./BrandPill";
import {useEffect, useState} from "react";
import {InitControlValueData} from "../Navigation/CarFilterBar";

export const BrandPillSelection = ({makes, fnFilterChange}) => {
    const [controlValueData, setControlValueData] = useState(InitControlValueData);

    const changeFilterValue = (value, elementName) => {
        setControlValueData(prevState => ({
            ...prevState, [elementName]: value
        }));
    }

    useEffect(() => {
        fnFilterChange(controlValueData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlValueData]);

    return (
        <div className="flex flex-wrap">
            {makes.map((make) => (
                <div key={make.make_id} className="mr-3 mb-3">
                    <BrandPill id={make.make_id} imageSrc={make.make_logo} title={make.make_name} active={false} onChange={(value) => changeFilterValue(value, 'make_id')} />
                </div>
            ))}
        </div>
    )
}