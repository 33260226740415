import React from "react";
import {axiosInstance} from "../../Api/AxiosConfig";

export const AddToBasketButton = ({hash, carInBasket, fnUpdate, text, className}) => {
    const addItemToBasket = (hash) => {
        axiosInstance
            .post("shop/add-to-basket", { carHash: hash })

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    return (
        <button
            className={`rounded-lg px-6 h-[30px] flex items-center justify-center hover:scale-[1.15] duration-300 ` + className + ' ' + (carInBasket !== null ? ' bg-gray-200' : ' bg-purchase-color')}
            onClick={(e) => {e.stopPropagation(); addItemToBasket(hash);}}
        >
            <span className={"flex material-symbols-outlined text-[20px] " + (carInBasket !== null ? ' text-gray-400' : 'text-white ')} title="Add this car to order list" >
                shopping_cart
            </span>
            <span className={`text-xs ml-2 whitespace-nowrap ${text === undefined || text === '' ? 'hidden' : ''} ${carInBasket !== null ? 'text-gray-400' : 'text-white'}`}>
                {text}
            </span>
        </button>
    )
}
