import React, {useEffect, useState} from "react";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {AddToBasketButton} from "../ShoppingBasket/AddToBasketButton";
import {Tooltip} from "@mantine/core";
import {axiosInstance} from "../../Api/AxiosConfig";
import {CarAttribute} from "./CarAttribute";
import {CarImageModal} from "./CarImageModal";
import {CarNavigationThumbs} from "./CarNavigationThumbs";
import {CarStatusPill} from "./CarStatusPill";
import {VATIcon} from "./VATIcon";
import {CalendarDaysIcon, DocumentIcon} from "@heroicons/react/24/outline";

export const CarListRow = ({car, showPrice, selectedCarId, setSelectedCarId, fnUpdate}) => {
    const [expandedView, setExpandedView] = useState(false);
    const [data, setData] = useState([]);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showImageIndex, setShowImageIndex] = useState(0);
    const [showPrimaryImage, setShowPrimaryImage] = useState(false);

    useEffect(() => {
        if (expandedView) {
            axiosInstance
                .get(car.info_endpoint)

                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedView]);

    useEffect(() => {
        if (car.hash !== selectedCarId) {
            setExpandedView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCarId]);

    const handleThumbnailClick = (index) => {
        setShowImageIndex(index);
        setShowImageModal(true);
    }

    const handlePrimaryImageClick = (e) => {
        e.stopPropagation();
        setExpandedView(true);
        setShowPrimaryImage(true);
    }

    useEffect(() => {
        if (showPrimaryImage && data.images) {
            if (data.images.all.length > 0) {
                setShowImageIndex(0);
                setShowImageModal(true);
                setSelectedCarId(car.hash);
            }
        } else {
            setShowImageModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, showPrimaryImage]);

    useEffect(() => {
        if (car.hash === selectedCarId) {
            setExpandedView(true);
        }
    }, [selectedCarId]);

    return (
        <Tooltip label='Click to see or hide all details' withArrow={true} openDelay={1500} transition='pop'>
            <div
                key={'carListRow_' + car.hash}
                className={"mr-6 mb-4 rounded-lg flex items-center bg-white pr-4 relative hover:scale-[1.01] transition duration-150 ease-in-out " + (car.in_basket ? 'border-[1px] border-purchase-color ' : ' border border-slate-200 border-solid block ') + (expandedView ? '' : ' min-h-[60px] ')}
                style={{display: 'block'}}
            >
                <div className={'absolute top-0 left-0 flex rounded-lg items-center justify-center bg-white opacity-80 w-full h-full pointer-events-none ' + (car.is_sold === 1 ? ' block ' : ' hidden ')}>
                    <span className='text-3xl font-bold'>SOLD</span>
                    <span className="material-symbols-outlined text-[40px] ml-4">eco</span>
                </div>
                <div className={'absolute pointer-events-none top-0 left-0 flex rounded-lg items-center justify-center bg-purchase-color opacity-20 w-full h-full ' + (car.in_basket ? ' block ' : ' hidden ')}>
                    <span className='text-3xl font-bold'></span>
                </div>
                <div className="flex items-center justify-between rounded-l-[5px] h-[60px] overflow-hidden cursor-pointer" onClick={() => {
                    setShowPrimaryImage(false);
                    setExpandedView(!expandedView);
                    setSelectedCarId(car.hash)
                }}>
                    <div className='flex items-center'>
                        <div className="h-full w-[80px]" onClick={(e) => handlePrimaryImageClick(e)}>
                            <img className='h-full object-scale-down' src={car.list_image} alt={'image for ' + car.designation}/>
                        </div>
                        <Tooltip label={car.designation} withArrow={true} openDelay={1500} transition='pop'>
                            <div className="ml-8 mr-8 text-[18px] font-medium min-w-[350px] max-w-[350px] text-ellipsis truncate overflow-hidden">
                                <span>{car.designation}</span>
                                <span className="block text-xs font-light screen-medium:hidden">{car.info_line_1}</span>
                            </div>
                        </Tooltip>
                        <div className='float-right w-[350px] text-ellipsis truncate mr-4 hidden screen-medium:block'>
                            <div className="block mr-3 font-light text-slate-700 text-ellipsis truncate overflow-hidden w-[400px]">
                                <span className="hidden screen-medium:block">{car.info_line_1}</span>
                            </div>
                        </div>
                        <div className="mr-3 font-light text-slate-700 w-[100px]">
                            <div className={(car.buy_status === 'confirmed' && !car.is_sold ? '' : 'hidden') + ' relative'}>
                                <div className="flex items-center">
                                    <CarStatusPill title='Confirmed' explanation="Carzelle has purchased the car so we can guarantee that it is available for your purchase" bgColor='bg-confirmed' className='text-xs'/>
                                    {car.in_stock_expected < 3 &&
                                        <Tooltip label={"Expected within " + car.in_stock_expected + " week(s)"}>
                                            <div className="ml-2"><CalendarDaysIcon className="h-5 w-5"/></div>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                            <div className={(car.buy_status === 'fast_delivery' && !car.is_sold ? '' : 'hidden') + ' '}>
                                <CarStatusPill title='Fast Delivery' explanation="Car is in stock in one of our locations and can be available for delivery immediately" bgColor='bg-fast-delivery' className='text-xs'/>
                            </div>
                            <div className={(car.buy_status === 'listing' && !car.is_sold ? '' : 'hidden') + ' '}>
                                <CarStatusPill title='Reservable' explanation="Reserve this car now, and we'll promptly confirm its availability with you." bgColor='bg-reservable' className='text-xs'/>
                            </div>
                            <div>{car.km === null ? '-' : formatNumberWithSeparator(car.km, '.')} km</div>
                        </div>
                    </div>
                    <div className="hidden">
                        {car.allow_offers !== 1 &&
                            <div className="rounded bg-blue-500 text-white text-xs p-1 min-w-[70px] text-center">Make Bid!</div>
                        }
                    </div>
                    <div className='flex items-center'>
                        <div className='flex justify-center'>
                            <AddToBasketButton hash={car.hash} fnUpdate={fnUpdate} carInBasket={car.in_basket}/>
                        </div>
                        <div className="flex items-center justify-end text-xl text-right w-[170px]" style={{display: showPrice && !car.is_sold ? 'flex' : 'none'}}>
                            <div>{formatNumberWithSeparator(parseInt(car.price), '.')}&nbsp;{car.currency}</div>
                            <div className='flex items-center ml-2'><VATIcon vatStatusId={car.vat_status_id}/></div>
                        </div>
                    </div>
                </div>
                <div className={(car.new_on_list === 'true' ? '' : 'hidden') + ' absolute left-0 top-[-14px]'}>
                    <CarStatusPill title='New' bgColor='bg-new-on-list' className='text-xs'/>
                </div>
                <div className={(car.price_info !== "" && car.price_info !== undefined ? '' : 'hidden') + ' absolute left-0 top-[-14px]'}>
                    <CarStatusPill title={car.price_info} bgColor='bg-new-on-list' className='text-xs'/>
                </div>

                {/* EXPANDED VIEW */}
                {data.length !== 0 && expandedView &&
                    <div className={`border-t border-t-gray-200`}>
                        <div className="ml-6 mt-6 font-bold">{car.designation}</div>
                        <div className='flex mt-4 ml-6'>
                            <div className=''>
                                <div className='flex'>
                                    <div className='flex-1 text-sm max-w-[250px] min-w-[250px]'>
                                        <CarAttribute label='Car #' value={car.car_id}/>
                                        <CarAttribute label='VIN' value={data.cars.vin_no.value}/>
                                        <CarAttribute label='Make' value={data.cars.make_name.value}/>
                                        <CarAttribute label='Model' value={data.cars.model.value}/>
                                        <CarAttribute label='Model year' value={data.cars.model_year.value}/>
                                        <CarAttribute label='First reg.' value={data.cars.reg_date.value}/>
                                    </div>
                                    <div className='border-r border-l-solid w-[1px]'></div>
                                    <div className='flex-1 text-sm ml-4 max-w-[250px] min-w-[250px]'>
                                        <CarAttribute label='KM' value={data.cars.km.value}/>
                                        <CarAttribute label='Color' value={data.cars.color.value === null ? data.cars.factory_color.value : data.cars.color.value}/>
                                        <CarAttribute label='Car type' value={data.cars.car_type_name.value}/>
                                        <CarAttribute label='Body type' value={data.cars.body_type_name.value}/>
                                        <CarAttribute label='Seats' value={data.cars.seats.value}/>
                                    </div>
                                    <div className='border-r border-l-solid w-[1px]'></div>
                                    <div className='flex-1 text-sm ml-4 max-w-[250px] min-w-[250px]'>
                                        <CarAttribute label='Fuel' value={data.cars.fuel_name.value}/>
                                        <CarAttribute label='CO2 rating' value={data.cars.co2_rating_wltp.value}/>
                                        <CarAttribute label='Gear' value={data.cars.gear_name.value}/>
                                        {data.cars.battery_size.value !== null && <CarAttribute label='Battery size' value={data.cars.battery_size.value}/>}
                                        {data.cars.fuel_name.value !== 'Electric' && <CarAttribute label='Engine size' value={data.cars.engine_size.value}/>}
                                        <CarAttribute label='HP' value={data.cars.horsepower.value}/>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    {data.documents.length > 0 &&
                                        <div className="mb-4">
                                            <div className="font-bold text-sm mb-1">Documents</div>
                                            <div className='flex-1 text-sm max-w-[250px] min-w-[250px]'>
                                                {data.documents.map((item) => (
                                                    <div key={item.file_url} className="flex mb-1">
                                                        <div><img src="/images/pdf_new.png" alt="pdf document" className="w-6 mr-3"/></div>
                                                        <div><a href={item.file_url} target="_blank" className="underline">{item.cars_documents_types_name}</a></div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>

                                {data.dkPrice.status &&
                                    <div className='' style={{display: showPrice && !car.is_sold ? 'block' : 'none'}}>
                                        <div className="mt-6 font-bold">Suggested Price Calculation</div>
                                        <div className="flex mt-4">
                                            <div className='max-w-[750px] whitespace-normal'>
                                                <div className="flex items-center justify-between">
                                                    <div className='text-sm'>Price</div>
                                                    <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.dk_price), '.')}&nbsp;{car.currency}</div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div className='text-sm'>Tax</div>
                                                    <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.dk_tax_amount), '.')}&nbsp;{car.currency}</div>
                                                </div>
                                                <div className="flex items-center justify-between border-b border-b-solid ">
                                                    <div className='text-sm'>VAT</div>
                                                    <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.vat_amount), '.')}&nbsp;{car.currency}</div>
                                                </div>
                                                <div className="flex items-center justify-between mt-2">
                                                    <div className='text-sm'>DK Total Net Price</div>
                                                    <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.total_price), '.')}&nbsp;{car.currency}</div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div className='text-sm'>DK Suggested Retail Price</div>
                                                    <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.potential_tender_price), '.')}&nbsp;{car.currency}</div>
                                                </div>
                                                <div className="flex items-center justify-between mt-2 border-b border-dashed border-t">
                                                    <div className='text-sm'>Potential Profit</div>
                                                    <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.profit), '.')}&nbsp;{car.currency}</div>
                                                </div>
                                                {data.dkPrice.tax_document &&
                                                    <div className="mt-4">
                                                        <div className='text-sm flex items-center'><DocumentIcon className="w-4 mr-2"/><a href={data.dkPrice.tax_document} className="text-sm underline" target="_new">Tax calculation documentation</a></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                {data.cars.condition.value !== null &&
                                    <div className='mt-5'>
                                        <div className='text-base font-semibold mb-3'>Condition</div>
                                        <div className='max-w-[750px] whitespace-normal'>
                                            {data.cars.condition.value}
                                        </div>
                                    </div>
                                }

                                {data.equipment.length > 0 &&
                                    <div className='mt-5'>
                                        <div className='text-base font-semibold mb-3'>Equipment</div>
                                        <div className='max-w-[750px] whitespace-normal'>
                                            {data.equipment.map((item) => (
                                                <span key={item.name} className='mb-[10px] whitespace-nowrap mr-2 px-2 py-1 rounded bg-gray-200 inline-block text-sm' style={{lineHeight: '15px'}}>{item.name}</span>
                                            ))}
                                        </div>
                                    </div>
                                }

                                <div className='flex flex-wrap items-center my-4'>
                                    <CarImageModal images={data.images.all} visible={showImageModal} setVisible={setShowImageModal} imageIndex={showImageIndex}/>
                                    <CarNavigationThumbs images={data.images.all} maxImagesNumber={20} onClick={handleThumbnailClick}/>
                                </div>

                            </div>
                        </div>

                    </div>
                }
            </div>
        </Tooltip>
    )
}