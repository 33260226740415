import {useEffect, useState} from "react";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {axiosInstance} from "../../Api/AxiosConfig";
import {Tooltip} from "@mantine/core";
import {XMarkIcon} from "@heroicons/react/24/solid";

export const ShoppingBasketMobile = ({showPrice, basketData, showShadow, fnUpdate, close}) => {

    const deleteItem = (hash) => {
        axiosInstance
            .delete("shop/remove-from-basket/" + hash)

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    if (basketData) {
        return (
            <>
                <div className={`bg-white p-4 w-full rounded border-t-[5px] border-t-purchase-color ` + (showShadow ? 'shadow-lg' : '')}>
                    <div className='flex items-center justify-between'>
                        <div className='flex text-2xl font-semibold text-gray-700'>
                            Cars to Order
                            <span className='ml-3 text-white text-base font-extrabold bg-purchase-color rounded-full p-4 w-[20px] h-[20px] flex items-center justify-center'>{basketData.basket_lines.length}</span>
                        </div>
                        <div className='text-white font-extrabold cursor-pointer hover:scale-[1.1] bg-gray-300 rounded-full p-2 flex items-center justify-center' onClick={close}>
                            <XMarkIcon className="text-black w-4"/>
                        </div>
                    </div>
                    <hr className='mt-2'/>
                    <div className='mt-6'>
                        {basketData.basket_lines.map((basketItem) => (
                            <div key={'basket_item_' + basketItem.basketHash} className={'mb-4 relative ' + (basketItem.is_sold === 1 ? 'line-through' : '')}>
                                <Tooltip label='This car has been sold to another partner. Please remove it before proceeding' withArrow={true} openDelay={200} transition='pop'>
                                    <div className={'absolute top-0 right-[10px] flex justify-center bg-gray-200 w-[120px] px-2 rounded bg-red-700 text-white ' + (basketItem.is_sold === 1 ? '' : 'hidden')}>Sold</div>
                                </Tooltip>
                                <div className='flex mt-2 justify-between'>
                                    <div>
                                        {basketItem.cars_designation}
                                    </div>
                                    <div className='flex items-center'>
                                        <div className='mr-2' style={{display: showPrice ? 'block' : 'none'}}>
                                            {formatNumberWithSeparator(parseFloat(basketItem.price), '.')}&nbsp;{basketItem.currency}
                                        </div>
                                        <div>
                                            <Tooltip label='Remove from basket' withArrow={true} openDelay={1500} transition='pop'>
                                                <button className='rounded-full w-[10px] h-[10px]' onClick={() => deleteItem(basketItem.basketHash)}>
                                                    <span className="material-symbols-outlined text-slate-500 text-base">
                                                        cancel
                                                    </span>
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-4" style={{display: basketData.discount_type === null ? 'none' : 'block'}}>
                        <div className="font-bold">Discount</div>
                        <div className="flex items-center justify-between">
                            <div className="font-gray-500 text-sm pr-2">{basketData.discount_text}</div>
                            <div className="font-gray-500">-&nbsp;{formatNumberWithSeparator(parseFloat(basketData.discount_total_amount), '.')}&nbsp;{basketData.discount_currency}</div>
                        </div>
                    </div>
                    <div className='flex items-center justify-center '>
                        <a href='/Checkout' className='w-full'>
                            <button className={'w-full rounded text-white font-semibold px-4 py-2 mt-8 mb-6 ' + (basketData.length === 0 ? ' bg-gray-300 text-black' : 'bg-purchase-color hover:scale-[1.05] duration-300')}>{basketData.length === 0 ? 'Add items to place order' : 'Go to Cart ...'}</button>
                        </a>
                    </div>
                    <div className='text-slate-400 text-sm'>Please note, when ready to make an Order: Click “Go to Cart” and finalize order by “Click here to Place Order”</div>
                </div>
            </>
        )
    }
}