import {CustomerManagerInformationBox} from "../../Components/Information/CustomerManagerInformationBox";
import {OrderField} from "../../Components/Order/OrderField";
import {formatNumberWithSeparator, getFormattedDate} from "../../Hooks/UseGenericFunctions";
import {CheckoutLines} from "../../Components/Checkout/CheckoutLines";
import {CarStatusPill} from "../../Components/Cars/CarStatusPill";
import {OrderTimeLine} from "../../Components/Order/OrderTimeline";
import React from "react";
import {CarzelleDeliveryInfo} from "../Generic/CarzelleDeliveryInfo";

export const CheckoutDesktop = ({doesBasketContainSoldCars, orderCheckoutPending, fnPlaceOrder, companyData, userData, basket, basketTotal}) => {
    let orderPendingClass= orderCheckoutPending ? 'animate-pulse' : '';
    return (
        <div className="overflow-auto ml-10">
            <div className="flex items-center justify-between py-2">
                <div className="flex items-center mt-10">
                    <div className="pr-4 text-3xl font-bold">
                        Checkout
                    </div>
                </div>
            </div>

            <div className="mb-[30px]"></div>

            <div className="mb-[40px] screen-large:mb-[80px] flex w-full justify-left">
                <div className='hidden mr-8 screen-large:block'>
                    {/* CUSTOMER MANAGER INFORMATION */}
                    <div className='w-[400px]'>
                        <CustomerManagerInformationBox title="Any Questions?"/>
                    </div>
                </div>

                {/* ORDER DETAILS */}
                <div className='min-w-[1000px] max-w-[1000px] relative'>
                    {doesBasketContainSoldCars &&
                        <div className='text-red-500 absolute top-[-50px] font-bold left-0 z-10 w-full flex justify-center'>One or more of the cars you have added to your basket have been sold. Please remove them and try again</div>
                    }
                    {doesBasketContainSoldCars === false &&
                        <div className='w-full flex justify-center absolute top-0 left-0 z-10'>
                            {!orderCheckoutPending &&
                                <button className={`${orderPendingClass} rounded shadow-lg text-white font-semibold px-12 py-2 mt-8 mb-6 bg-purchase-color hover:scale-[1.05] duration-300`} onClick={fnPlaceOrder}>Click here to place order</button>
                            }

                            {orderCheckoutPending &&
                                <div className="mt-4 font-bold animate-pulse text-red-600">Please wait while we process your order</div>
                            }
                        </div>
                    }

                    <div className='rounded-xl p-8 bg-white relative'>
                        <div className='absolute right-[20px] top-[20px] mr-3'><img src="/images/logo.png" className="min-w-[192px] max-w-[192px]" alt='Carzelle logo'/></div>
                        <div className='text-3xl font-semibold text-gray-700'>ORDER</div>
                        <div className='mt-8 flex justify-between'>
                            <div>
                                <OrderField id='customer_name' label='Customer' text={companyData.name} textClass='font-bold'/>
                                <div>{companyData.address}</div>
                                <div>{companyData.zip_code}&nbsp;{companyData.city}</div>
                                <div>{companyData.country}</div>
                                <div>Att. {userData.name}</div>
                                <div className='mt-4'>
                                    <OrderField id='order_date' label='Order date' text={getFormattedDate(new Date(), '.')}/>
                                </div>
                            </div>
                            <div className='mr-6'>
                                <CarzelleDeliveryInfo/>
                            </div>
                        </div>
                        <hr className='mt-8'/>

                        <div className='mt-8 px-4'>
                            <CheckoutLines lines={basket.basket_lines.filter(item => item.buy_status === 'fast_delivery')} discountType={basket.discount_type} title={<CarStatusPill title='Fast Delivery' bgColor='bg-fast-delivery' className='text-base'/>}/>
                            <div className='mt-8'></div>
                            <CheckoutLines lines={basket.basket_lines.filter(item => item.buy_status === 'confirmed')} discountType={basket.discount_type}  title={<CarStatusPill title='Confirmed' bgColor='bg-confirmed' className='text-base'/>}/>
                            <div className='mt-8'></div>
                            <CheckoutLines lines={basket.basket_lines.filter(item => item.buy_status === 'listing')} discountType={basket.discount_type}  title={<CarStatusPill title='Cars Reserved' bgColor='bg-gray-300' className='text-base'/>}/>
                        </div>

                        <div className='mt-8'>
                            <div className='flex justify-between w-full bg-gray-200 rounded py-2 px-4'>
                                <div className=''>
                                    Total
                                </div>
                                <div>
                                    {formatNumberWithSeparator(basketTotal, '.')}&nbsp;{basket.basket_lines.length === 0 ? '' : basket.basket_lines[0].currency}
                                </div>
                            </div>
                        </div>

                        <div className='mt-2' style={{display: basket.discount_type === null ? 'none' : 'block'}}>
                            <div className='flex justify-between w-full rounded py-2 px-4 text-sm'>
                                <div className=''>
                                    Total discount (already deducted from each car)
                                </div>
                                <div>
                                    -&nbsp;{formatNumberWithSeparator(parseFloat(basket.discount_total_amount), '.')}&nbsp;{basket.discount_currency}
                                </div>
                            </div>
                        </div>

                        <div className='mt-[50px]'>
                            <div className='text-xl mb-4 capitalize'>Delivery overview</div>
                            <div className='text-sm text-gray-600 mb-8 leading-7'>
                                <b>Please note</b>: We will start the process of shipping cars for <CarStatusPill title='Confirmed' bgColor='bg-confirmed' className='text-xs'/> at once. <br/>
                                For <CarStatusPill title='Reservable' bgColor='bg-reservable' className='text-xs'/> cars, we will start the process of shipping as soon as we have confirmed the availability.
                            </div>
                            <OrderTimeLine/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mr-8 mb-[100px] screen-large:hidden'>
                {/* CUSTOMER MANAGER INFORMATION */}
                <div className='w-[400px] mb-[80px]'>
                    <CustomerManagerInformationBox title="Any Questions?"/>
                </div>
            </div>
        </div>
    )
}